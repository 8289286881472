import React, { useState } from "react"
import toaster from "toasted-notes"
import { Link } from "gatsby"
import "../../css/toaster.css"

function Notification({ message, error, onClose }) {
  return (
    <div className="bg-gray-600 opacity-95 shadow-lg w-full py-3 px-10">
      <div className="text-left flex flex-col sm:flex-row">
        <div className="w-full sm:w-9/12">
          <p className="text-white text-xs font-light mb-0">
            We use cookies on this site to enhance your user experience. By
            clicking any link on this page you are giving your consent for us to
            set cookies. Read our{" "}
            <Link to="/cookies-policy" className="text-blue-400 font-semibold">
              Cookie Policy
            </Link>
            .
          </p>
        </div>

        <div className="flex w-full sm:w-3/12 sm:justify-end mt-4 sm:mt-0">
          <div className="flex">
            <button
              className="bg-blue-400 hover:bg-gray-300 text-white text-xs px-6 py-1 rounded-full my-auto h-fit-content focus:outline-none"
              aria-label="Accept"
              onClick={() => {
                localStorage.setItem("cookie_contest", "accepted")
                onClose()
              }}
            >
              Accept
            </button>
            <button
              className="bg-white hover:bg-gray-300 text-blue-400 text-xs px-6 py-1 rounded-full my-auto h-fit-content focus:outline-none ml-2"
              aria-label="Not agree"
              onClick={() => {
                localStorage.setItem("cookie_contest", "declined")
                onClose()
              }}
            >
              No, thank you
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

let _cookieConsentAccepted = false

function CookieToast() {
  const [cookieConsentAccepted, toggleNotification] = useState(true)

  React.useEffect(() => {
    toggleNotification(
      !!localStorage.getItem("cookie_contest") || _cookieConsentAccepted
    )

    if (!cookieConsentAccepted) {
      toaster.notify(
        props => {
          return <Notification {...props} />
        },
        {
          duration: null,
          position: "bottom",
        }
      )
      toggleNotification(true)
      _cookieConsentAccepted = true
    }
  }, [cookieConsentAccepted])
  return null
}

export default CookieToast
