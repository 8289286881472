import { Link } from "gatsby"
import React from "react"
import Logo from "../../svg/logo_footer.svg"
import EmailIcon from "../../svg/email_footer.svg"
import TwitterIcon from "../../svg/twitter.svg"
import InstagramIcon from "../../svg/instagram.svg"
import FacebookIcon from "../../svg/facebook.svg"

function Footer(props) {
  return (
    <div className="footer main-container relative z-10">
      <div className="absolute w-full h-full flex -z-1">
        <div className="relative shape-one bg-blue-400 h-full w-full">
          <div className="hidden lg:block shape-two absolute w-1/5 bg-blue-200 h-1/2 bottom-0"></div>
        </div>
      </div>
      <div className="shape-three absolute w-1/5 bg-blue-200 bottom-0 right-0 h-full -z-1"></div>
      <div className="container mx-auto justify-center lg:text-center pt-10 lg:pt-20 pb-3 px-3 md:px-6">
        <Link to="/" className="hidden lg:block">
          <img src={Logo} className="w-24 mx-auto mt-5 mb-6" alt="Logo" />
        </Link>
        <div className="menu-footer flex flex-col lg:flex-row justify-center">
          {props.menu.map(link => {
            return (
              <Link
                to={link.url}
                key={link.url}
                className="text-white hover:text-yellow-200 lg:px-3 text-xs border-b border-blue-200 py-1 lg:border-none"
              >
                {link.label}
              </Link>
            )
          })}
        </div>
        <div className="social_media mx-auto flex lg:justify-center mt-6">
          <Link
            to="https://twitter.com/appmanus"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={TwitterIcon}
              className="bg-white rounded-full md:p-2.5 p-2 md:mr-5 mr-2 "
              alt="Manus twitter"
            />
          </Link>
          <Link
            to="https://www.facebook.com/manusappofficial"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={FacebookIcon}
              className="w-7 lg:w-8 h-7 lg:h-9 mr-2 lg:mr-5 mb-0"
              alt="Manus facebook"
            />
          </Link>
          <Link
            to="https://www.instagram.com/appmanus/?hl=en"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={InstagramIcon}
              className="w-7 lg:w-8 h-7 lg:h-9 mr-2 lg:mr-5 mb-0"
              alt="Manus instagram"
            />
          </Link>
          <a href="mailto:help@manusapp.com">
            <img
              src={EmailIcon}
              className="w-7 lg:w-8 h-7 lg:h-9 mb-0"
              alt="Manus email"
            />
          </a>
        </div>
        <p className="text-white text-xs-s font-light pt-6 md:py-4 mt-2 mb-0 leading-normal">
          Manus App Ltd {new Date().getFullYear()}. All rights reserved. Manus is registered
          Trademarks Registry under No. UK00003234401 and No. UK00003217854 of
          Manus App Limited, a limited company registered in England and Wales
          No. 10517144, with registered office at 470A Green Lanes, London, N13
          5PA, United Kingdom, VAT No. GB340899179. Manus App Limited is
          registered with the Information Commissioner's Office (ICO), No.
          ZB035236. Manusapp.com is a website owned and operated by Manus App
          Limited.{" "}
        </p>
      </div>
    </div>
  )
}
export default Footer
