import React, { useEffect, lazy } from "react"
import PropTypes from "prop-types"
import Footer from "./common/footer"
import "./layout.css"
import CookieConsent from "./common/cookieConsent"
import { useStaticQuery, graphql } from "gatsby"

const isBrowser = typeof window !== "undefined"
const Layout = ({ children }) => {
  const Menu = useStaticQuery(graphql`
    query MyQuery {
      header: wpMenu(slug: { eq: "main-menu" }) {
        name
        menuItems {
          nodes {
            label
            url
          }
        }
      }
      footer: wpMenu(slug: { eq: "footer" }) {
        name
        menuItems {
          nodes {
            label
            url
          }
        }
      }
    }
  `)

  if (isBrowser) {
    localStorage.setItem(
      "headerMenu",
      JSON.stringify(Menu.header.menuItems.nodes)
    )
  }

  useEffect(() => {
    //recaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js"
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  }, [])

  return (
    <>
      <main>{children}</main>
      <Footer menu={Menu.footer.menuItems.nodes} />
      <CookieConsent />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
